export const AppProperties = {
    version : "1.0.6",
    
};

export const GUIProperties = {
    Windows : {
        Id : {

        }
    },
    Modals : {
        Id : {
            Nothing : 0,
            Loading : 1,
            ConfirmBox : 2
        }
    },
    Languages : {
        Czech : "cs",
        English : "en"
    },
};