import React from 'react';

interface DeadFundIconProps {
    className: string;
}

const DeadFundIcon:React.FC<DeadFundIconProps> = (props) => {
    return (   
      <svg 
            className = { props.className }
            viewBox="0 0 120 121"
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
      >
          <path fill-rule="evenodd" clip-rule="evenodd" d="M34.6642 26.2958L33.6844 36.9407L32.2005 35.8202L29.5628 33.9633L29.6189 36.7015C29.6189 36.7015 29.6189 39.7068 26.5284 40.0503C23.4378 40.3939 22.3225 39.3131 22.3225 39.3131C22.3225 39.3131 20.3654 33.2344 20.1836 31.1137" fill="#5B6D45"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M34.6636 26.4581L33.6838 36.9406L32.1999 35.8202L29.5622 33.9633L29.6183 36.7014C29.6183 36.7014 29.6183 39.7067 26.5278 40.0503C23.4373 40.3939 22.3219 39.3131 22.3219 39.3131C22.3219 39.3131 20.5891 37.7906 20.4072 35.6699" fill="#5B6D45"/>
          <path d="M34.6636 26.4581L33.6838 36.9406L32.1999 35.8202L29.5622 33.9633L29.6183 36.7014C29.6183 36.7014 29.6183 39.7067 26.5278 40.0503C23.4373 40.3939 22.3219 39.3131 22.3219 39.3131C22.3219 39.3131 20.5891 37.7906 20.4072 35.6699" stroke="black" stroke-width="0.999611"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1309 32.369L20.1821 28.5705C20.1821 28.5705 20.6089 25.3986 22.238 24.9427C23.8671 24.4869 31.0125 24.1495 31.0125 24.1495C31.0125 24.1495 33.1496 24.1232 34.6649 26.416" fill="#5B6D45"/>
          <path d="M20.1309 32.369L20.1821 28.5705C20.1821 28.5705 20.6089 25.3986 22.238 24.9427C23.8671 24.4869 31.0125 24.1495 31.0125 24.1495C31.0125 24.1495 33.1496 24.1232 34.6649 26.416" stroke="black" stroke-width="0.999611"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M29.5662 34.1403L29.6183 34.1223V36.7014C29.6183 36.7014 29.6183 39.7067 26.5278 40.0503C23.4373 40.3939 22.3219 39.313 22.3219 39.313C22.3219 39.313 20.5891 37.7906 20.4072 35.6698" fill="#5B6D45"/>
          <path d="M29.5662 34.1403L29.6183 34.1223V36.7014C29.6183 36.7014 29.6183 39.7067 26.5278 40.0503C23.4373 40.3939 22.3219 39.313 22.3219 39.313C22.3219 39.313 20.5891 37.7906 20.4072 35.6698" stroke="black" stroke-width="0.999611"/>
          <path d="M65.4082 88.5708L80.9318 87.4341V90.4653L83.5359 89.5181L85.2383 87.3393L91.5482 86.0131" fill="#5B6D45"/>
          <path d="M44.0762 66.3731L44.1759 64.9521L69.8149 66.7522C69.8149 66.7522 73.0325 66.5096 73.5525 63.4029C74.0724 60.2961 76.4249 34.261 76.4249 34.261C76.2246 34.4506 65.4634 23.5811 64.9077 21.6833C64.352 19.7855 62.9983 18.9422 65.1081 16.2838C67.2179 13.6254 70.0153 15.0527 70.0153 15.0527C70.1226 14.9179 73.801 17.0987 73.6209 17.3258L81.3326 23.7673L85.3385 28.5986L85.4634 30.0701L89.645 38.4498L91.7486 50.3855C91.7486 50.3855 91.8483 74.0669 91.7486 74.1617C91.6488 74.2565 91.4105 86.4648 91.4105 86.4648L65.7088 88.6548C64.661 88.8531 65.4082 75.6775 65.4082 75.6775L62.9048 75.488L56.5949 73.9722L50.7859 71.2253L44.3763 66.4887L44.1759 64.8786" fill="#5B6D45"/>
          <path d="M77.4375 20.4851C77.4375 20.4851 83.733 26.0729 84.9223 28.128C85.5566 29.2241 85.2599 29.8501 85.7111 30.996C86.454 32.883 88.5557 35.5782 89.4711 38.2018C90.4661 41.0531 90.9232 44.9291 91.2587 47.5409C91.4982 49.4053 91.5016 50.031 91.5791 52.368C91.784 58.5451 91.5791 86.157 91.5791 86.157" stroke="black" stroke-width="0.999611"/>
          <path d="M65.2849 88.6752C65.2849 88.6752 65.5351 77.9662 65.4222 76.3438C65.3942 75.9424 65.5079 75.7645 65.3092 75.5966C64.9326 75.2783 63.6592 75.7293 62.4442 75.5164C60.144 75.1134 55.8003 73.8368 52.7481 72.3057C49.5682 70.7105 43.7939 66.0195 43.7939 66.0195" stroke="black" stroke-width="0.999611"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M47.6056 48.0218L43.9983 66.0069C43.9983 66.0069 42.7324 69.2836 38.4683 69.7248C34.2042 70.1661 25.2761 61.2174 25.2761 61.2174C25.2761 61.2174 23.2109 57.7516 25.1428 54.7897C27.0747 51.8278 30.14 51.7648 30.14 51.7648L27.4812 49.4474C27.4812 49.4474 25.5563 47.3075 25.679 44.976C25.8017 42.6444 27.1625 41.0677 27.1625 41.0677C27.1625 41.0677 28.6345 38.7286 33.6304 39.5665" fill="#485737" fill-opacity="0.91"/>
          <path d="M47.6056 48.0218L43.9983 66.0069C43.9983 66.0069 42.7324 69.2836 38.4683 69.7248C34.2042 70.1661 25.2761 61.2174 25.2761 61.2174C25.2761 61.2174 23.2109 57.7516 25.1428 54.7897C27.0747 51.8278 30.14 51.7648 30.14 51.7648L27.4812 49.4474C27.4812 49.4474 25.5563 47.3075 25.679 44.976C25.8017 42.6444 27.1625 41.0677 27.1625 41.0677C27.1625 41.0677 28.6345 38.7286 33.6304 39.5665" stroke="black" stroke-width="0.999611"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M35.1992 22.5887L30.4972 17.9243C30.4972 17.9243 29.1413 17.2059 29.7919 16.0771C30.2125 15.3474 32.531 12.8918 34.2396 11.5042C35.1749 10.7448 36.5193 10.6021 36.5193 10.6021" fill="#485737" fill-opacity="0.91"/>
          <path d="M35.1992 22.5887L30.4972 17.9244C30.4972 17.9244 29.1413 17.206 29.7919 16.0772C30.4425 14.9483 30.9854 14.538 30.9854 14.538" stroke="black" stroke-width="0.999611"/>
          <path d="M33.6436 12.1263L34.9227 11.038C34.9227 11.038 35.5194 10.4735 36.3333 10.6276" stroke="black" stroke-width="0.999611"/>
          <path d="M35.9757 13.9932L32.1242 17.4623C32.1242 17.4623 31.5275 18.078 30.6055 17.9244" stroke="black" stroke-width="0.999611"/>
          <path d="M28.7805 12.3504L31.7448 15.205C31.7448 15.205 32.5587 16.0771 33.6435 15.205C34.7284 14.333 34.6949 13.5068 34.4985 13.3056C34.442 13.2476 33.8492 12.5032 33.5195 12.1567C32.8875 11.4917 31.5466 10.5253 31.7403 10.0581C32.3728 8.53273 31.3978 7.16845 30.192 6.91213C28.9862 6.65581 28.0213 7.65605 28.0213 7.65605L27.8686 8.70764L26.8323 8.73979C26.8323 8.73979 25.9235 9.23365 25.8943 10.4251C25.8652 11.6165 26.1658 12.58 29.2576 12.5287" fill="#C9C0C0"/>
          <path d="M28.7805 12.3504L31.7448 15.205C31.7448 15.205 32.5586 16.0771 33.6435 15.205C34.7284 14.333 34.6949 13.5068 34.4985 13.3056C34.442 13.2476 33.8492 12.5032 33.5195 12.1567C32.8875 11.4917 31.5466 10.5253 31.7403 10.0581C32.3728 8.53273 31.3978 7.16845 30.192 6.91213C28.9862 6.65581 28.0213 7.65605 28.0213 7.65605L27.8686 8.70764L26.8323 8.73979C26.8323 8.73979 25.9235 9.23365 25.8943 10.4251C25.8652 11.6165 26.1658 12.58 29.2576 12.5287" stroke="black" stroke-width="0.999611"/>
          <path className="animate-pulse" d="M42.412 5.54114L73.1687 8.15863C73.1687 8.15863 78.7137 8.15863 78.3875 11.7066C78.361 11.9955 78.4056 16.0032 77.5878 20.4659C77.484 21.0315 74.4831 17.6559 73.238 16.8168C72.5786 16.3726 71.4226 15.3803 69.5084 14.6727C68.4354 14.2761 64.6016 15.0113 63.8857 17.949C63.59 19.1621 64.1157 20.4454 64.9812 21.8798C68.4712 27.6617 76.3404 34.1298 76.3404 34.1298L73.6578 63.5376C73.6578 63.5376 72.1902 66.9311 69.5808 66.7771C66.9714 66.6231 44.3011 64.9257 44.3011 64.9257C44.3011 64.9257 44.7235 61.3363 43.315 59.8351C41.9066 58.3339 39.0266 56.4453 39.0266 56.4453C39.0266 56.4453 42.2809 56.4382 43.756 54.9028C45.231 53.3674 45.932 50.6255 44.9534 48.7744C43.9749 46.9234 43.2025 46.4625 42.1326 45.5454C41.0627 44.6282 34.1892 39.7819 33.7001 39.6274C33.2111 39.4729 36.6357 9.54711 36.6357 9.54711" fill="#FFD890"/>
          <path d="M42.412 5.54114L73.1687 8.15863C73.1687 8.15863 78.7137 8.15863 78.3875 11.7066C78.361 11.9955 78.4056 16.0032 77.5878 20.4659C77.484 21.0315 74.4831 17.6559 73.238 16.8168C72.5786 16.3726 71.4226 15.3803 69.5084 14.6727C68.4354 14.2761 64.6016 15.0113 63.8857 17.949C63.59 19.1621 64.1157 20.4454 64.9812 21.8798C68.4712 27.6617 76.3404 34.1298 76.3404 34.1298L73.6578 63.5376C73.6578 63.5376 72.1902 66.9311 69.5808 66.7771C66.9714 66.6231 44.3011 64.9257 44.3011 64.9257C44.3011 64.9257 44.7235 61.3363 43.315 59.8351C41.9066 58.3339 39.0266 56.4453 39.0266 56.4453C39.0266 56.4453 42.2809 56.4382 43.756 54.9028C45.231 53.3674 45.932 50.6255 44.9534 48.7744C43.9749 46.9234 43.2025 46.4625 42.1326 45.5454C41.0627 44.6282 34.1892 39.7819 33.7001 39.6274C33.2111 39.4729 36.6357 9.54712 36.6357 9.54712" stroke="black" stroke-width="0.999611"/>
          <path d="M36.5986 9.61891L36.8621 8.225C36.8621 8.225 37.9148 4.48996 42.9169 5.63506" fill="#FFD890"/>
          <path d="M36.5986 9.61891L36.8621 8.225C36.8621 8.225 37.9148 4.48996 42.9169 5.63506" stroke="black" stroke-width="0.999611"/>
          <path d="M58.4331 12.2506L63.1761 12.5808C63.1761 12.5808 66.5469 12.5657 66.5301 13.6704C66.5208 14.2865 66.5297 14.3746 66.4935 14.9574C66.4935 14.9574 65.6531 15.4901 64.5064 16.7963C63.6695 17.7498 63.768 19.3654 64.329 20.8102C64.652 21.6426 65.7881 22.698 65.7255 23.4528C65.5392 25.7046 65.3909 27.4562 65.3596 27.4266C65.2717 27.3435 65.3596 29.5828 63.606 29.5828C61.8524 29.5828 56.3869 29.1056 55.8903 28.9474C55.3938 28.7892 53.7417 28.062 53.8737 26.4314C54.0057 24.8007 55.0138 14.241 55.0138 14.241C55.0138 14.241 55.1532 11.851 58.573 12.2656" fill="#424242"/>
          <path d="M58.4331 12.2506L63.1761 12.5808C63.1761 12.5808 66.5469 12.5657 66.5301 13.6704C66.5208 14.2865 66.5297 14.3746 66.4935 14.9574C66.4935 14.9574 65.6531 15.4901 64.5064 16.7963C63.6695 17.7498 63.768 19.3654 64.329 20.8102C64.652 21.6426 65.7881 22.698 65.7255 23.4528C65.5392 25.7046 65.3909 27.4562 65.3596 27.4266C65.2717 27.3435 65.3596 29.5828 63.606 29.5828C61.8524 29.5828 56.3869 29.1056 55.8903 28.9474C55.3938 28.7892 53.7417 28.062 53.8737 26.4314C54.0057 24.8007 55.0138 14.241 55.0138 14.241C55.0138 14.241 55.1532 11.851 58.573 12.2656" stroke="black" stroke-width="0.343402"/>
          <path d="M44.2778 11.8431L44.3246 11.8114L46.5125 11.9692C46.5125 11.9692 46.0119 18.2787 45.9788 18.31C45.9457 18.3413 43.644 18.1522 43.6104 18.1522C43.5769 18.1522 44.3546 11.6256 44.3546 11.6256" fill="#A0A09E"/>
          <path d="M44.2778 11.8431L44.3246 11.8114L46.5125 11.9692C46.5125 11.9692 46.0119 18.2787 45.9788 18.31C45.9457 18.3413 43.644 18.1522 43.6104 18.1522C43.5769 18.1522 44.3546 11.6256 44.3546 11.6256" stroke="black" stroke-width="0.343402"/>
          <path d="M43.353 20.0171L43.3998 19.9853L45.5877 20.1431C45.5877 20.1431 45.0871 26.4527 45.054 26.484C45.0209 26.5153 42.7192 26.3262 42.6856 26.3262C42.6521 26.3262 43.4298 19.7996 43.4298 19.7996" fill="#A0A09E"/>
          <path d="M43.353 20.0171L43.3998 19.9853L45.5877 20.1431C45.5877 20.1431 45.0871 26.4527 45.054 26.484C45.0209 26.5153 42.7192 26.3262 42.6856 26.3262C42.6521 26.3262 43.4298 19.7996 43.4298 19.7996" stroke="black" stroke-width="0.343402"/>
          <path d="M42.5606 28.2619L42.6065 28.2298L44.7454 28.3893C44.7454 28.3893 44.2564 34.7564 44.2237 34.7881C44.1915 34.8195 41.9409 34.6287 41.9082 34.6287C41.8756 34.6287 42.6356 28.0424 42.6356 28.0424" fill="#A0A09E"/>
          <path d="M42.5606 28.2619L42.6065 28.2298L44.7454 28.3893C44.7454 28.3893 44.2564 34.7564 44.2237 34.7881C44.1915 34.8195 41.9409 34.6287 41.9082 34.6287C41.8756 34.6287 42.6356 28.0424 42.6356 28.0424" stroke="black" stroke-width="0.343402"/>
          <path d="M41.7774 36.5218L41.8237 36.4905L43.979 36.6462C43.979 36.6462 43.4859 42.8593 43.4533 42.8902C43.4206 42.9206 41.1533 42.7345 41.1202 42.7345C41.0871 42.7345 41.8533 36.3076 41.8533 36.3076" fill="#A0A09E"/>
          <path d="M41.7774 36.5218L41.8237 36.4905L43.979 36.6462C43.979 36.6462 43.4859 42.8593 43.4533 42.8902C43.4206 42.9206 41.1533 42.7345 41.1202 42.7345C41.0871 42.7345 41.8533 36.3076 41.8533 36.3076" stroke="black" stroke-width="0.343402"/>
          <path d="M57.8507 53.25L62.6056 53.4942L61.9493 61.9299L57.2773 61.4915L58.0881 53.1656" fill="#ABACA8"/>
          <path d="M57.8507 53.25L62.6056 53.4942L61.9493 61.9299L57.2773 61.4915L58.0881 53.1656" stroke="black" stroke-width="0.343402"/>
          <path d="M66.1846 62.7193L69.5068 27.7075L66.1846 62.7193Z" fill="#D8D8D8"/>
          <path d="M66.1846 62.7193L69.5068 27.7075" stroke="black" stroke-width="0.343402"/>
          <path d="M70.6729 15.0721L70.8529 13.171L70.6729 15.0721Z" fill="#D8D8D8"/>
          <path d="M70.6729 15.0721L70.8529 13.171" stroke="black" stroke-width="0.343402"/>
          <path d="M38.3931 64.7666C38.5035 64.6689 41.0458 61.9842 41.0458 61.9842C41.0458 61.9842 38.4924 59.6034 38.4567 59.6034C38.4209 59.6034 36.4069 58.3109 35.0762 58.9229C33.7454 59.5349 32.2713 60.5218 32.0192 62.0865C31.7672 63.6511 32.4151 64.8755 32.6305 65.0454C32.8459 65.2154 35.2218 67.5239 35.2218 67.5239" fill="#C2BF99"/>
          <path d="M38.3931 64.7666C38.5035 64.6689 41.0458 61.9842 41.0458 61.9842C41.0458 61.9842 38.4924 59.6034 38.4567 59.6034C38.4209 59.6034 36.4069 58.3109 35.0762 58.9229C33.7454 59.5349 32.2713 60.5218 32.0192 62.0865C31.7672 63.6511 32.4151 64.8755 32.6305 65.0454C32.8459 65.2154 35.2218 67.5239 35.2218 67.5239" stroke="black" stroke-width="0.999611" stroke-linecap="round"/>
          <path d="M35.2207 67.5118L35.8563 66.8906L35.7729 66.2356C35.7729 66.2356 35.6148 65.7677 35.9803 65.8094C36.3458 65.8512 37.1243 66.1747 37.1371 66.1572C37.1499 66.1396 37.089 65.0442 37.089 65.0442C37.089 65.0442 36.9933 64.6927 37.4095 64.919C37.8257 65.1452 38.3849 64.7637 38.3849 64.7637" fill="#C2BF99"/>
          <path d="M35.2207 67.5118L35.8563 66.8906L35.7729 66.2356C35.7729 66.2356 35.6148 65.7677 35.9803 65.8094C36.3458 65.8512 37.1243 66.1747 37.1371 66.1572C37.1499 66.1396 37.089 65.0442 37.089 65.0442C37.089 65.0442 36.9933 64.6927 37.4095 64.919C37.8257 65.1452 38.3849 64.7637 38.3849 64.7637" stroke="black" stroke-width="0.999611"/>
          <path d="M39.5006 51.8385C39.5967 51.7302 41.4669 48.9488 41.4669 48.9488C41.4669 48.9488 38.8773 46.8934 38.8417 46.8972C38.8062 46.9009 36.8653 45.7912 35.628 46.5358C34.3904 47.2804 33.0783 48.1218 33.0476 49.6984C33.0172 51.2745 33.7778 52.4029 34.0159 52.5485C34.2537 52.6946 36.8682 54.6895 36.8682 54.6895" fill="#C2BF99"/>
          <path d="M39.5006 51.8385C39.5967 51.7302 41.4669 48.9488 41.4669 48.9488C41.4669 48.9488 38.8773 46.8934 38.8417 46.8972C38.8062 46.9009 36.8653 45.7912 35.628 46.5358C34.3904 47.2804 33.0783 48.1218 33.0476 49.6984C33.0172 51.2745 33.7778 52.4029 34.0159 52.5485C34.2537 52.6946 36.8682 54.6895 36.8682 54.6895" stroke="black" stroke-width="0.999666" stroke-linecap="round"/>
          <path d="M36.7842 54.7182C36.7842 54.7182 37.471 54.3445 37.4284 54.0444C37.3881 53.7605 37.1964 53.4193 37.1964 53.4193C37.1964 53.4193 36.9937 52.9878 37.3527 52.9931C37.7108 52.9985 38.4935 53.1763 38.5547 53.13C38.6159 53.0837 38.3112 52.2839 38.3112 52.2839C38.3112 52.2839 38.1913 51.6841 38.6247 51.8702C39.0586 52.0563 39.5505 51.8046 39.5505 51.8046" fill="#C2BF99"/>
          <path d="M36.7842 54.7182C36.7842 54.7182 37.471 54.3445 37.4284 54.0444C37.3881 53.7605 37.1964 53.4193 37.1964 53.4193C37.1964 53.4193 36.9937 52.9878 37.3527 52.9931C37.7108 52.9985 38.4935 53.1763 38.5547 53.13C38.6159 53.0837 38.3112 52.2839 38.3112 52.2839C38.3112 52.2839 38.1913 51.6841 38.6247 51.8702C39.0586 52.0563 39.5505 51.8046 39.5505 51.8046" stroke="black" stroke-width="0.999666"/>
          <path d="M20.0843 35.7484C20.0649 35.1468 20 32.703 20 32.703C20 32.703 20.5018 31.1897 21.8229 31.0285C23.1439 30.8674 23.4966 30.9283 23.5932 30.9672C23.6899 31.006 25.3124 31.4214 25.5454 32.89C25.7785 34.3586 25.6761 35.3426 25.5454 35.3893C25.4148 35.4361 23.019 35.4353 23.019 35.4353H22.3366C22.3366 35.4353 22.2793 35.1084 22.2139 34.8926C22.1486 34.6767 22.0753 34.5728 21.9125 34.8771C21.7496 35.1814 21.6556 35.4511 21.6556 35.4511L21.5016 35.4586C21.5016 35.4586 21.4437 34.9852 21.3868 34.8846C21.3299 34.784 21.2076 34.5999 21.0571 34.8926C20.9066 35.1852 20.4926 35.6194 20.4926 35.6194" fill="#C2BF99"/>
          <path d="M20.0843 35.7484C20.0649 35.1468 20 32.703 20 32.703C20 32.703 20.5018 31.1897 21.8229 31.0285C23.1439 30.8674 23.4966 30.9283 23.5932 30.9672C23.6899 31.006 25.3124 31.4214 25.5454 32.89C25.7785 34.3586 25.6761 35.3426 25.5454 35.3893C25.4148 35.4361 23.019 35.4353 23.019 35.4353H22.3366C22.3366 35.4353 22.2793 35.1084 22.2139 34.8926C22.1486 34.6767 22.0753 34.5728 21.9125 34.8771C21.7496 35.1814 21.6556 35.4511 21.6556 35.4511L21.5016 35.4586C21.5016 35.4586 21.4437 34.9852 21.3868 34.8846C21.3299 34.784 21.2076 34.5999 21.0571 34.8926C20.9066 35.1852 20.4926 35.6194 20.4926 35.6194" stroke="black" stroke-width="0.999611"/>
          <path d="M82.5859 91.2216L85.774 91.3665L85.8225 105.778C85.8225 105.778 85.962 108.138 89.7208 108.525C93.4795 108.913 93.5612 105.54 93.5612 105.54C93.5612 105.54 93.6507 91.1373 93.6163 91.1373C93.5819 91.1373 95.6903 90.9148 95.6903 90.9148" fill="#91A775"/>
          <path d="M82.5859 91.2216L85.774 91.3665L85.8225 105.778C85.8225 105.778 85.962 108.138 89.7208 108.525C93.4795 108.913 93.5612 105.54 93.5612 105.54C93.5612 105.54 93.6507 91.1373 93.6163 91.1373C93.5819 91.1373 95.6903 90.9148 95.6903 90.9148" stroke="black" stroke-width="0.999611"/>
          <path d="M59.9199 91.3356C59.9199 91.3356 62.1197 91.3711 62.1458 91.3711C62.1718 91.3711 62.5302 95.0352 65.5219 95.0844C68.5135 95.1337 69.4152 93.3678 69.4152 93.3678L69.8385 91.3694C69.8385 91.3694 75.0948 91.3694 75.1209 91.3694C75.1469 91.3694 75.248 95.0515 78.0834 95.0026C80.9188 94.9538 81.4828 94.2357 81.4828 94.2357C81.4828 94.2357 83.9152 91.0572 82.8189 90.8401" fill="#91A775"/>
          <path d="M59.9199 91.3356C59.9199 91.3356 62.1197 91.3711 62.1458 91.3711C62.1718 91.3711 62.5302 95.0352 65.5219 95.0844C68.5135 95.1337 69.4152 93.3678 69.4152 93.3678L69.8385 91.3694C69.8385 91.3694 75.0948 91.3694 75.1209 91.3694C75.1469 91.3694 75.248 95.0515 78.0834 95.0026C80.9188 94.9538 81.4828 94.2357 81.4828 94.2357C81.4828 94.2357 83.9152 91.0572 82.8189 90.8401" stroke="black" stroke-width="0.999611"/>
          <path d="M59.8616 91.3427L59.3682 89.2717C59.3682 89.2717 80.988 87.4365 80.935 87.3885C80.8821 87.3401 81.0847 90.4907 81.0847 90.4907C81.0847 90.4907 83.25 89.7776 83.6972 89.3685C84.1443 88.9594 85.1877 87.3488 85.8219 87.1952C86.4562 87.0416 91.4009 85.9495 92.4302 86.0985C93.4595 86.2476 95.3305 86.9276 95.3141 86.9535L95.7431 91.1402" fill="#91A775"/>
          <path d="M59.8616 91.3427L59.3682 89.2717C59.3682 89.2717 80.988 87.4365 80.935 87.3885C80.8821 87.3401 81.0847 90.4907 81.0847 90.4907C81.0847 90.4907 83.25 89.7776 83.6972 89.3685C84.1443 88.9594 85.1877 87.3488 85.8219 87.1952C86.4562 87.0416 91.4009 85.9495 92.4302 86.0985C93.4595 86.2476 95.3305 86.9276 95.3141 86.9535L95.7431 91.1402" stroke="black" stroke-width="0.999611"/>
          <path d="M58.585 114.921L58.7606 91.4187L62.3053 91.4237C62.3053 91.4237 62.5701 94.7442 65.3248 95.0769C68.0794 95.4096 69.3682 93.3586 69.3682 93.4141C69.3682 93.4696 69.7332 91.5247 69.7787 91.3076C69.8241 91.0905 73.3405 91.2441 75.155 91.331C75.3841 91.3418 74.9357 94.8552 78.4521 95.0213C81.9685 95.1875 82.3539 92.3947 82.3539 92.3947C82.3539 92.3947 82.5679 91.5794 82.6037 91.3218C82.6394 91.0642 85.9232 91.2416 85.9232 91.3527C85.9232 91.4637 85.8848 105.445 85.9537 105.719C86.0115 105.949 86.3911 108.311 89.4697 108.436C92.5483 108.561 93.0704 107.471 93.4072 106.028C93.665 104.923 93.5723 91.1966 93.5723 91.1966L98.4949 91.4187L98.9054 115.136" fill="url(#paint0_linear_401_332)"/>
          <path d="M58.585 114.921L58.7606 91.4187L62.3053 91.4237C62.3053 91.4237 62.5701 94.7442 65.3248 95.0769C68.0794 95.4096 69.3682 93.3586 69.3682 93.4141C69.3682 93.4696 69.7332 91.5247 69.7787 91.3076C69.8241 91.0905 73.3405 91.2441 75.155 91.331C75.3841 91.3418 74.9357 94.8552 78.4521 95.0213C81.9685 95.1875 82.3539 92.3947 82.3539 92.3947C82.3539 92.3947 82.5679 91.5794 82.6037 91.3218C82.6394 91.0642 85.9232 91.2416 85.9232 91.3527C85.9232 91.4637 85.8848 105.445 85.9537 105.719C86.0115 105.949 86.3911 108.311 89.4697 108.436C92.5483 108.561 93.0704 107.471 93.4072 106.028C93.665 104.923 93.5723 91.1966 93.5723 91.1966L98.4949 91.4187L98.9054 115.136" stroke="black" stroke-width="0.999611"/>
          <path d="M58.4404 114.686L99.0579 114.941L58.4404 114.686Z" fill="#D8D8D8"/>
          <path d="M58.4404 114.686L99.0579 114.941" stroke="black" stroke-width="0.999611"/>
          <defs>
              <linearGradient id="paint0_linear_401_332" x1="78.7452" y1="91.189" x2="78.7452" y2="115.136" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#474E2E"/>
                  <stop offset="1" stop-color="#0D0E08"/>
              </linearGradient>
          </defs>
      </svg>
    );
}

export default DeadFundIcon;