import React, { Fragment, useState } from 'react';

import { useTranslation } from 'react-i18next';
import ClickAwayListener from 'react-click-away-listener';
import HeaderButton from '../../Components/Buttons/HeaderButton';
import DeadWebsiteIcon from '../../Icons/Main/DeadWebsiteIcon';
import ZombieSlutsIcon from '../../Icons/Main/ZombieSlutsIcon';
import DeadFundIcon from '../../Icons/Main/DeadFundIcon';
import ZombieDexIcon from '../../Icons/Main/ZombieDexIcon';
import CommingSoon from '../../Components/Overlays/CommingSoon';

interface MainRouteMenuProps {
}

const MainRouteMenu: React.FC<MainRouteMenuProps>  = (props) => {

    const [mainRouteMenuOpen, setMainRouteMenuOpen] = useState(false);

    const handleToggleMenu = () => {
        setMainRouteMenuOpen(!mainRouteMenuOpen);
    };
  
    const handleCloseMenu = () => {
        setMainRouteMenuOpen(false);
    };

    const handleDeadWebsiteClick = () => {
        window.open('https://www.dead-website.com', '_blank', 'noreferrer');
    };

    const handleZombieSlutsClick = async() => {
    };
        
    const handleZombieDexClick = () => {
    };
    
    const { t } = useTranslation("translations");
    
    return (
        <div className = { "flex flex-col opacity-80 hover:opacity-100" }>
            <HeaderButton additionalClassName = { "flex h-12 shrink-0 items-center" } 
                        handleClick = { handleToggleMenu }
                        active={false}>
                    <div className = { "w-12 h-12 flex items-center justify-center" }>
                        <DeadFundIcon className="h-10" />
                    </div>
                    { mainRouteMenuOpen === true &&
                        <div className = { "text-left mx-2 " }>
                            <span>{ t('app.deadfund') }</span>
                        </div>
                    } 

            </HeaderButton>
            { mainRouteMenuOpen === true &&
                <ClickAwayListener onClickAway = { handleCloseMenu }>
                    <div className = { "flex flex-col" }>
                        <HeaderButton additionalClassName = { "flex h-12 items-center z-50" }  
                                handleClick = { handleDeadWebsiteClick }
                                active={false}>
                            <div className = { "w-12 h-12 flex items-center justify-center" }>
                                <DeadWebsiteIcon className="h-10" />                
                            </div>
                            <div className = { "text-left mx-2" }>
                                <span>{ t('app.deadwebsite') }</span>
                            </div>
                        </HeaderButton>
                        
                        <HeaderButton additionalClassName = { "flex h-12 items-center" }
                                handleClick = { handleZombieSlutsClick }
                                active={false}>
                            <div className = { "w-12 h-12 flex items-center justify-center" }>
                                <ZombieSlutsIcon className="h-10" />
                            </div>
                            <CommingSoon>
                                <div className = { "text-left mx-2 w-full" }>
                                    <span>{ t('app.zombiesluts') }</span>
                                </div>
                            </CommingSoon>
                        </HeaderButton>
                    
                        <HeaderButton additionalClassName = { "flex h-12 items-center" } 
                                handleClick = { handleZombieDexClick }
                                active={false}>
                                
                            <div className = { "w-12 h-12 flex items-center justify-center" }>
                                <ZombieDexIcon className="h-10" />
                            </div>
                            <CommingSoon>
                                <div className = { "text-left mx-2 w-full" }>
                                    <span>{ t('app.zombiedex') }</span>
                                </div>
                            </CommingSoon>
                        </HeaderButton>
                        
                    </div>
                </ClickAwayListener>
            }
        </div>
    );
}

export default MainRouteMenu;