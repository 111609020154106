import React from "react";


interface ModalWindowProps {
    children?: React.ReactNode
}

const ModalWindow: React.FC<ModalWindowProps> = (props) => {
    return (
            <div className = { "inset-0 w-full h-full fixed bg-brand-blur bg-opacity-50 " }>
                { props.children }  
            </div>
    );
}

export default ModalWindow;