import React from 'react';

interface TwitterIconProps {
    className: string;
}

const TwitterIcon:React.FC<TwitterIconProps> = (props) => {
    return (    
        <svg className={props.className} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <circle cx="512" cy="512" r="448" fill="#1da1f2"/>
            <path d="M778 354.8c-18.8 8.3-38.9 13.9-60.1 16.5 21.6-13 38.2-33.5 46-57.9-20.2 11.8-42.7 20.4-66.5 25.2-19.1-20.4-46.2-33.2-76.4-33.2-57.8 0-104.7 46.9-104.7 104.6 0 8.3 1 16.3 2.7 23.9-87-4.1-164.2-45.9-215.8-109.1-9.1 15.4-14.2 33.2-14.2 52.7 0 36.4 18.5 68.4 46.6 87.2-17.2-.6-33.3-5.3-47.4-13.1v1.3c0 50.8 36 93.1 84 102.7-8.8 2.4-18.1 3.6-27.6 3.6-6.7 0-13.1-.6-19.5-1.8 13.4 41.6 52 71.9 98 72.7-35.7 28.1-81.1 44.8-129.8 44.8-8.3 0-16.6-.5-24.9-1.4 46.6 29.7 101.5 47 160.8 47C621.7 720.5 727 561 727 422.9c0-4.4 0-8.9-.3-13.4 20.4-14.7 38.3-33.2 52.3-54.2l-1-.5z" fill="currentColor" />
        </svg>
    );
}

export default TwitterIcon;