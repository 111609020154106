import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'ts/Components/Buttons/Link';
import ShinyIconButton from 'ts/Components/Buttons/ShinyIconButton';
import BigWindow from 'ts/Components/Windows/BigWindow';
import DeadWebsiteIcon from 'ts/Icons/Main/DeadWebsiteIcon';
import DonateIcon from 'ts/Icons/Main/DonateIcon';
import { getDonatesSum } from 'ts/Services/DonatesService';
import { ErrorVO } from 'ts/Slices/GuiSlice';

interface GoalWindowProps {
}

const GoalWindow: React.FC<GoalWindowProps> = (props) => {

    const { t } = useTranslation("translations");
    
    const goalAmount = 10000000;

    const handleDonateButtonClick = () => {
        window.open('https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=JLJ24QX7DBZTA&source=url', '_blank', 'noreferrer');
          
    };

    
    const [amount, setAmount] = useState<number>(0);
    const [fetching, setFetching] = useState<boolean>(false);

    useEffect(() => {
        function delay(ms: number) {
            return new Promise( resolve => setTimeout(resolve, ms) );
        }

        setFetching(true);
        const fetchData = async () => {
            await delay(3000);
            let donatesSumResult: ErrorVO | number = await getDonatesSum();
            if(donatesSumResult as Number) {
                let donatesSumResultNum = donatesSumResult as number;
                setAmount(donatesSumResultNum);
            } else if(donatesSumResult as ErrorVO) {
                let error = donatesSumResult as ErrorVO;
                console.log(error.message);
            }
            setFetching(false);
        }
        fetchData();        
    }, []);

    return (
        <BigWindow
            title={ t('goal.title') }
        >
            <div
                className="flex flex-col w-full max-w-md mx-auto"
            >            
                <Link additionalClassName="flex flex-col items-center justify-center text-brand-blood-500 hover:text-brand-blood-600" href="http://www.dead-website.com">
                    <DeadWebsiteIcon className="h-24" />
                    <span className="text-xl mx-4">DEAD-WEBSITE</span>
                </Link>
                <div className="flex items-center border-2 border-brand-green-100 h-[600px] bg-brand-gray-300 bg-opacity-60 p-4 my-4 rounded-2xl ">
                    <div className="basis-4/12 h-full p-2 flex flex-col justify-between">
                        <span className="text-xl text-brand-green-600 border-t border-brand-green-300 -mb-[1px]">10 000 000 Kč</span>
                        <span className="text-lg text-brand-green-500 border-r border-brand-green-300 -mb-[1px]">5 000 000 Kč</span>
                        <span className="text-base text-brand-green-400 border-b border-brand-green-300 -mb-[1px]">0 Kč</span>
                    </div>
                    <div className="basis-4/12 rounded-2xl bg-brand-horror-cyan-100 h-full mx-auto p-2 my-4 flex flex-col-reverse items-end">
                        <div className="rounded w-full bg-brand-green-500 flex justify-end items-start mx-auto" 
                                style = { { minHeight:"1px", height : (amount/goalAmount * 100) + "%" } }>   
                        </div>
                    </div>
                    <div className="basis-4/12 flex flex-col-reverse items-end h-full p-2">
                        <div className="w-full mx-auto" 
                                style = { { height : (amount/goalAmount * 100) + "%" } }>   
                        </div>
                        <span className="text-right w-full text-sm text-brand-green-900 border-b border-brand-green-900 -mb-[1px]">{ amount.toFixed(2) } Kč - {(amount/goalAmount * 100).toFixed(4)} %</span>
                    </div>
                </div>
                
                <ShinyIconButton handleButtonClick={handleDonateButtonClick}
                    icon={ <DonateIcon className={'h-8'} /> }
                    text={ t('goal.donate') } />

                <span className="text-brand-horror-turqoise-900 mt-8">{ t('goal.currentlyCollected') } : <span className="text-brand-green-500">{ amount.toFixed(2) } Kč</span></span>
                <span className="text-brand-horror-turqoise-900">{ t('goal.goalAmount') } : <span className="text-brand-green-500">10 000 000 Kč</span></span>
                <span className="text-brand-horror-turqoise-900">{ t('goal.reached') } : <span className="text-brand-green-500">{ (amount/goalAmount * 100).toFixed(4) } %</span></span>
                <span className="text-brand-horror-turqoise-900">{ t('goal.startOfCampaign') } : <span className="text-brand-green-500">1.6.2023</span></span>
                <span className="text-brand-horror-turqoise-900">{ t('goal.endOfCampaign') } : <span className="text-brand-green-500">{ t('goal.unlimited') }</span></span>
            </div>           
            
        </BigWindow>
    );
}


export default GoalWindow;