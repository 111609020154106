import React from 'react';
import { useTranslation } from 'react-i18next';
import CommingSoon from 'ts/Components/Overlays/CommingSoon';
import BigWindow from 'ts/Components/Windows/BigWindow';

interface VlogWindowProps {
}

const VlogWindow: React.FC<VlogWindowProps> = (props) => {

    const { t } = useTranslation("translations");
    
    return (
        <BigWindow
            title={ t('vlog.title') }
        >
            <CommingSoon big={true}>
                <div
                    className="aspect-w-16 aspect-h-9 my-4"
                >
                    <iframe 
                            className="mx-auto rounded"
                            width="100%" 
                            height="100%" 
                            src="https://www.youtube.com/embed/OjOnge1P1BE" 
                            title="Developer Vlog youtube video" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    />
                </div>
            </CommingSoon>
        </BigWindow>
    );
}


export default VlogWindow;