import React from "react";
import HeaderButton from "./HeaderButton";

interface IconHeaderButtonProps {
    handleClick?: Function;
    icon: React.ReactNode;
    active: boolean;
    text: String | null;
}

const IconHeaderButton: React.FC<IconHeaderButtonProps> = (props) => {

    const handleClick = () => {
        if(props.handleClick) {
            props.handleClick();
        } 
    };

    return (
        <HeaderButton additionalClassName = { "flex h-12 flex-col items-center justify-center" } 
                handleClick = { handleClick }
                active = { props.active }> 
            <span className="relative w-full shrink-0 h-12 flex items-center justify-center text-2xl translate-y-6 opacity-80 ease-in-out transition-opacity  duration-600 mx-2">{ props.text }</span>
            <div className={`relative w-full shrink-0 h-12 flex items-center justify-center ease-in-out transition-opacity duration-600 -translate-y-6 pt-2 -pb-2 mx-2 ${ !props.active && "opacity-80 hover:opacity-10 " } ${ props.active && "opacity-10 " } `}>
                { props.icon }
            </div>
        </HeaderButton>               
    );
}

export default IconHeaderButton;