export default function ComingSoon({
    children,
    message = "Coming soon",
    big = false,
}: {
    children: React.ReactElement;
    message?: string;
    big?: Boolean;
}) {
    return(
        <div className="relative w-full">
            <div className={`${ !big && "blur-sm"} ${ big && "blur-md"} `}>{children}</div>
            <p className={`absolute top-1/2 mx-auto w-full -translate-y-1/2 text-center text-semibold text-brand-blood-900 blur-none ${ !big && " text-2xl "} ${ big && " text-6xl "} `}
            >
                {message}
            </p>
        </div>
    );
}
