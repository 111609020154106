import React from "react";
import Button from "./Button";

interface IconButtonProps {
    handleButtonClick?: Function;
    icon: React.ReactNode;
    disabled?: boolean;
    text: String | null;
    additionalClassName?: String;
}

const IconButton: React.FC<IconButtonProps> = ({
    handleButtonClick,
    icon,
    disabled=false,
    text,
    additionalClassName
}) => {

    const handleClick = () => {
        if(handleButtonClick) {
            handleButtonClick();
        } 
    };

    return (
        <button className = { `${ !disabled && "bg-gradient-to-r from-brand-green-100 via-brand-horror-cyan-300 to-brand-green-100 hover:from-brand-green-200 hover:via-brand-green-100 hover:to-brand-green-200 w-full flex h-12 my-1 flex-col items-center justify-center text-brand-green-600 hover:text-brand-green-900 hover:font-semibold shadow-3xl hover:shadow-4xl transition-color transition-shadow ease-in-out duration-600 cursor-brand-link " } 
                ${  disabled && "bg-brand-gray-400 bg-brand-gray-300 cursor-brand-unavailable " }
                text-4xl rounded tracking-widest py-8 ` + additionalClassName }
                onClick = { handleClick }
                disabled = { disabled !== true ? false : true }>
            <div className="relative animate-bounce w-full shrink-0 h-12 flex items-center justify-center opacity-40 ease-in-out transition-opacity transition-colors duration-600 mx-2">
                { icon }
            </div>
            <span className="relative w-full shrink-0 h-0 flex items-center justify-center text-2xl -translate-y-6 opacity-80 hover:opacity-10 ease-in-out transition-opacity transition-colors duration-600 mx-2">{ text }</span>
            
        </button>               
    );
}

export default IconButton;