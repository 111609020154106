import React from 'react';

import { GUIProperties } from '../../Properties';

import LanguageIcon from '../../Icons/Forms/LanguageIcon';

import i18n from '../../i18n';
import { guiActions } from '../../Slices/GuiSlice';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../Slices/Store';
import HeaderButton from '../../Components/Buttons/HeaderButton';

const LanguageButton: React.FC = () => {

    const dispatch = useDispatch();

    const settings  = useSelector((state: RootState) => state.gui.settings);

    const handleLanguageChange = async() => {
        
        let newSettings = { ...settings }
        if(settings.language === GUIProperties.Languages.Czech) {
            newSettings.language = GUIProperties.Languages.English;
            i18n.changeLanguage(GUIProperties.Languages.English);
        } else {
            newSettings.language = GUIProperties.Languages.Czech;
            i18n.changeLanguage(GUIProperties.Languages.Czech);
        }

        dispatch(guiActions.setSettings(newSettings));
    };

    return (
        <HeaderButton additionalClassName = { "h-12 flex items-center justify-center opacity-80 hover:opacity-100" }
                handleClick = { handleLanguageChange }
                active = {false}>
            <LanguageIcon language = { settings.language } className= { "h-11" } />
        </HeaderButton>
    );
};

export default LanguageButton; 