import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCrGL_N8oYspbJ24-S5I2TjcfSM8GS8k-0",
    authDomain: "dead-fund.firebaseapp.com",
    databaseURL: "https://dead-fund-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "dead-fund",
    storageBucket: "dead-fund.appspot.com",
    messagingSenderId: "274885591590",
    appId: "1:274885591590:web:d19a62d8035c50dfd01ce7"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;