import React from "react";

interface BigWindowProps {
    children?: React.ReactNode;
    handleCloseClick?: Function;
    title?: String;
}

const BigWindow: React.FC<BigWindowProps> = (props) => {

    return (
        <div 
                className = "flex flex-col max-w-4xl w-full bg-cover bg-scratches3 bg-no-repeat bg-center border-2 border-brand-horror-teal-300 outline outline-2 outline-offset-2 hover:outline-offset-2 outline-brand-horror-cyan-300 hover:outline-brand-horror-cyan-500 my-8 mx-auto rounded-3xl items-center z-10">
            <div className="flex flex-col w-full h-full bg-brand-gray-300 bg-opacity-40 hover:bg-opacity-60 p-8">
                <div className = { "w-full flex mb-4" }>
                    <h3 className = { "w-full text-4xl font-bold text-center text-brand-blood-500 mb-1" }>
                        { props.title }
                    </h3>
                </div>
                { props.children }
            </div>
        </div>
    );
}

export default BigWindow;