import React from "react";
import LoadingIcon from "ts/Icons/Main/LoadingIcon";

interface ScrollWindowProps {
    children?: React.ReactNode;
    handleCloseClick?: Function;
    loading: boolean;
    title?: String;
}

const ScrollWindow: React.FC<ScrollWindowProps> = (props) => {

    return (
        <div className = "flex flex-col w-full my-8 mx-auto items-center z-10">
            <div className = { "w-full flex mb-4 border-2 border-brand-horror-teal-300 bg-brand-green-300 bg-opacity-80 hover:bg-opacity-100 " }>
                <h3 className = { "text-4xl w-full font-bold text-center text-brand-blood-500 mb-1" }>
                    { props.title }
                </h3>
            </div>
            { props.children }
            <div className={`h-16 flex justify-center items-center transition-opacity ease-in-out duration-1000 w-full bg-gradient-to-r from-brand-gray-100 via-brand-horror-turqoise-300 to-brand-gray-100 ${ props.loading && " opacity-100 " } ${ !props.loading && " opacity-0 " } `}>

                    <LoadingIcon className={`h-12 `} />

            </div>
        </div>
    );
}

export default ScrollWindow;