import React from 'react';
import { useTranslation } from 'react-i18next';
import BigWindow from 'ts/Components/Windows/BigWindow';

interface AboutWindowProps {
}

const AboutWindow: React.FC<AboutWindowProps> = (props) => {

    const { t } = useTranslation("translations");
    
    return (
        <BigWindow
            title = { t('about.title') }
        >
            <div
                className="flex flex-col w-full mb-12"
            >            
                <span className="text-2xl text-center">Rogue-like RPG NFT hru se zombíkama pro WebGL.</span>

                <span className="text-brand-horror-turqoise-900 mt-4">Gameplay mechaniky</span>
                <span className="text-brand-horror-turqoise-700">Mávneš myší, mávneš mačetou. Končetiny budou lítat, krev bude cákat.</span>
                
                <span className="text-brand-horror-turqoise-900 mt-4">Postavy</span>
                <span className="text-brand-horror-turqoise-700">Každá postava ve hře bude vytvořena z realných lidí. Pokud se chcete zvěčnit v této hře, nabízíme to jako odměnu za donate.</span>
                
                <span className="text-brand-horror-turqoise-900 mt-4">Příběh</span>
                <span className="text-brand-horror-turqoise-700">Ve hře bude několik krátkých příběhů k NPC postavám.</span>
                
                <span className="text-brand-horror-turqoise-900 mt-4">Platforma</span>
                <span className="text-brand-horror-turqoise-700">WebGL - hru bude možno hrát pouze ve webovém prohlížeči na PC.</span>
                
                <span className="text-brand-horror-turqoise-900 mt-4">Proč NFT?</span>
                <span className="text-brand-horror-turqoise-700">Chceme využít výhod této nové technologie a vytvořit tak něco nového. Na NFT sice převládá negativní názor, kvůli toho, že se začali prodávat předražené obrázky, co nemají využití. Nicméně v téhle hře má NFT smysl. NFT postava bude sloužit jako vstupenka do hry. Jelikož je to rogue-like žánr, tak úmrtí se promítne do vaší NFT postavy a již s ní nepůjde hrát. Pro pokračování v hraní bude třeba koupit nové NFT. Peníze ve formě kryptoměny vybrané z prodeje NFT postav budou transparentní a budou vkládány do hry, aby je mohli vyhrát hráči co úspěšně přežívají. Strach, že příjdete o postavu bude jedním z horrorových prvků.</span>
                
                <span className="text-brand-horror-turqoise-900 mt-4">Proč WebGL?</span>
                <span className="text-brand-horror-turqoise-700">Ešte neexistuje žádná pořádná hra na WebGL.</span>
                                
                <span className="text-brand-horror-turqoise-900 mt-4">NO PAY TO WIN</span>
                <span className="text-brand-horror-turqoise-700">Za peníze nepůjde pořídit žádné zvýhodnění ve hře. K prodeji budou pouze NFT postavy.</span>
                                
                <span className="text-brand-horror-turqoise-900 mt-4">Crypto ke hře</span>
                <span className="text-brand-horror-turqoise-700">Ke hře vzniknou 2 nové krpytoměny..</span>
                                
                <span className="text-brand-horror-turqoise-900">Náboj</span>
                <span className="text-brand-horror-turqoise-700">Tato kryptoměna bude fungovat jako náboj. Nepůjde koupit, půjde získat pouze hraním. Můžete ho buďto prodat, nebo vystřelit. V případě, že ho vystřelíte, tak se navždy vyřadí z oběhu. Čím míň bude nábojů, tím větší by měly mít časem hodnotu. Na začátku bude vytvořeno absurdní množství nábojů.</span>
                                               
                <span className="text-brand-horror-turqoise-900">Coin</span>
                <span className="text-brand-horror-turqoise-700">Bude fungovat jako klasická kryptoměna, která by měla nabývat na hodnotě s popularitou téhle hry. Samozřejmě budeme usilovat o zaregistrování této kryptoměny na burzu. Za coiny si budete moct pořídit novou NFT postavu, nebo rozměnit zpátky na Ether.</span>
                
            </div>
        </BigWindow>
    );
}


export default AboutWindow;