import React from 'react';

interface RewardsIconProps {
    className: string;
}

const RewardsIcon:React.FC<RewardsIconProps> = (props) => {
    return ( 
        <svg className={props.className} fill="#0AEFA9" stroke="#6CF8CD" strokeWidth="1.2" xmlns="http://www.w3.org/2000/svg"
	            viewBox="0 0 490 490">
			<path fill="#07AB79" d="M143.9,354.7L121.7,490h246.9L346,354.7H143.9z M162.9,457.3l11.3-70.4h141.6l11.3,70.4H162.9z"/>
			<polygon fill="#27F5B6" points="140.8,321.2 245,266.4 349.2,321.2 329.4,204.9 413.8,122.5 297.1,105.8 245,0 192.9,105.8 76.2,122.5 
				160.6,204.9 			"/>
			<polygon fill="#4AF7C2" points="192.9,316.2 192.9,334.4 297.1,334.4 297.1,316.2 245,288.9 			"/>
        </svg>
    );
}

export default RewardsIcon;