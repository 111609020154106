
import React from 'react';

interface GoalIconProps {
    className: string;
}

const GoalIcon:React.FC<GoalIconProps> = (props) => {
    return (    
        <svg className={props.className} viewBox="0 0 92 92" fill="#0AEFA9" stroke="#08CD91" strokeWidth="1.2" xmlns="http://www.w3.org/2000/svg">
            <path d="M46 0C20.5936 0.00323437 0.00323437 20.5918 0 46C0.00323437 71.4064 20.5936 91.9968 46 92C71.4066 91.9968 91.9968 71.4064 92 46C91.9968 20.5918 71.4066 0.00323437 46 0ZM72.7188 72.719C65.8666 79.5631 56.4474 83.7825 46 83.7858C35.5524 83.7825 26.1318 79.5631 19.281 72.719C12.4369 65.8684 8.21603 56.4476 8.21423 46C8.21585 35.5524 12.4369 26.1318 19.281 19.281C26.1318 12.4369 35.5524 8.21603 46 8.21423C56.4476 8.21585 65.8668 12.4369 72.7188 19.281C79.5629 26.1318 83.784 35.5524 83.7856 46C83.7841 56.4476 79.5631 65.8684 72.7188 72.719Z" />
            <path d="M33.2725 29.7078V67.0283H37.938V50.7007H62.4286V29.7078H37.938H33.2725Z" />
        </svg>

    );
}

export default GoalIcon;