import { Timestamp } from "firebase/firestore";
import React from "react";

interface DonateWindowProps {
    handleCloseClick?: Function;
    name?: String;
    message?: String;
    amount?: Number;
    finalAmount?: Number;
    date?: Timestamp;
}

const DonateWindow: React.FC<DonateWindowProps> = (props) => {

    return (
        <div className = "flex flex-col max-w-4xl w-full bg-cover bg-scratches2 bg-no-repeat bg-center border-2 border-brand-green-300 outline outline-2 outline-offset-2 hover:outline-offset-2 outline-brand-horror-cyan-300 hover:outline-brand-green-500 my-8 mx-auto rounded-3xl items-center z-10">
            <div className="flex flex-col w-full h-full bg-brand-gray-300 bg-opacity-40 hover:bg-opacity-60 p-8">
                <span className="text-brand-horror-turqoise-900 text-4xl">{ props.name }</span>
                <span className="text-brand-green-500 text-xs">{ props.date?.toDate().toLocaleString() }</span>
                <span className="text-brand-horror-turqoise-700 text-xl">{ props.message }</span>
                <span className="text-right text-brand-green-700 text-4xl">{ props.amount?.toFixed(2) } Kč</span>
                <span className="text-right text-brand-green-500 text-sm">Bez poplatku pro PayPal : { props.finalAmount?.toFixed(2) } Kč</span>
            </div>
        </div>
    );
}

export default DonateWindow;