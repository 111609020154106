import React from "react";


interface LinkProps {
    children?: React.ReactNode;
    href: string;
    additionalClassName?: string;
}

const Link: React.FC<LinkProps> = ({
    children,
    href,
    additionalClassName
}) => {
    
    return (
        <a className = {"hover:font-semibold cursor-brand-link " + additionalClassName }
                href = { href }>
            { children }
        </a>                
    );
}

export default Link;