import db from '../Firebase';
import { collection, addDoc, getDocs, query, where, orderBy } from 'firebase/firestore';
import { DonateVO, ErrorVO } from 'ts/Slices/GuiSlice';


export const getDonates : () => Promise<ErrorVO | DonateVO[]> = async () => {
    return new Promise(async function(resolve, reject) {
        const collectionRef = collection(db, 'Donates');
        const q = query(collectionRef, orderBy('date', "desc"));
        
        getDocs(q).then((snapshot) => {
            if (snapshot.empty) {
                resolve([]);
            }
    
            let donates : DonateVO[] = [];
            snapshot.forEach(doc => {
                donates.push({ ...doc.data(), id:doc.id } as DonateVO);
            });
            resolve(donates);
        }).catch((error: string) => {
            resolve({message: "Error during loading donates. " + error});
        });
    });
};


export const getDonatesSum : () => Promise<ErrorVO | number> = async () => {
    return new Promise(async function(resolve, reject) {
        const collectionRef = collection(db, 'Donates');
        const q = query(collectionRef);
        getDocs(q)
        .then((snapshot) => {
            let resultAmount = 0;
            snapshot.forEach(doc => {
                resultAmount += doc.data().finalAmount;
            });

            resolve(resultAmount as number);
        }).catch((error: string) => {
            resolve({ message: "Error during loading account." + error } as ErrorVO);
        });
    });
};
