import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DonateWindow from 'ts/Components/Windows/DonateWindow';
import ScrollWindow from 'ts/Components/Windows/ScrollWindow';

import { getDonates } from 'ts/Services/DonatesService';
import { DonateVO, ErrorVO } from 'ts/Slices/GuiSlice';

interface DonateHistoryWindowProps {
    loading: boolean;
}

const DonateHistoryWindow: React.FC<DonateHistoryWindowProps> = (props) => {

    const { t } = useTranslation("translations");

    const [donates, setDonates] = useState<DonateVO[]>([]);
    const [fetching, setFetching] = useState<boolean>(false);
    const [loadedAll, setLoadedAll] = useState<boolean>(false);

    useEffect(() => {
        function delay(ms: number) {
            return new Promise( resolve => setTimeout(resolve, ms) );
        }

        if(props.loading) {
            setFetching(true);
            if(!loadedAll) {
                const fetchData = async () => {
                    await delay(3000);
                    let donatesResult: ErrorVO | DonateVO[] = await getDonates();
                    if(donatesResult as DonateVO[]) {
                        let downloadedDonates = donatesResult as DonateVO[];
                        setDonates(downloadedDonates);
                        setLoadedAll(true);
                    } else if(donatesResult as ErrorVO) {
                        let error = donatesResult as ErrorVO;
                        console.log(error.message);
                    }
                    setFetching(false);
                }

                fetchData();
            } else {
                const delayLoading = async () => {
                    await delay(3000);
                    setFetching(false);
                }   
                delayLoading();
            }
        }
    }, [props.loading]);
    
    return (
        <ScrollWindow
            title={t('donateHistory.title')} 
            loading={ props.loading && fetching }
        >   
            {donates.map(object => {
                return <DonateWindow
                    name={object.name}
                    message={object.message}
                    amount={object.amount}
                    date={object.date} 
                    finalAmount={object.finalAmount} />;
            })}            
        </ScrollWindow>
    );
}


export default DonateHistoryWindow;