import { configureStore, EnhancedStore } from '@reduxjs/toolkit';

import guiReducer from './GuiSlice';

const store: EnhancedStore = configureStore({
    reducer: { 
        gui: guiReducer
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>

export default store;

