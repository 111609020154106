import React from 'react';

import { useTranslation } from 'react-i18next';

import ModalWindow from '../../Components/Windows/ModalWindow';
import DeadWebsiteIcon from '../../Icons/Main/DeadWebsiteIcon';
import LoadingIcon from '../../Icons/Main/LoadingIcon';

const Loading: React.FC = (props) => {

    const { t } = useTranslation();
    const progression = 1;
    
    return (
        <ModalWindow>
            <div className = { "flex items-center max-w-xl mx-auto h-full" }>
                <div className = { "flex flex-col bg-brand-gray-300 border-2 border-brand-horror-teal-300 outline outline-2 outline-offset-2 hover:outline-offset-2 outline-brand-horror-cyan-500 hover:outline-brand-horror-cyan-700 p-4 w-full rounded-3xl" }>
                    <DeadWebsiteIcon className="mx-auto w-1/2 my-16" />
                    <div className="w-full text-center">
                        <span className = { "text-2xl text-white" }>
                            { t('app.loading') }
                        </span>
                    </div>
                    <div className="rounded-2xl h-16 bg-brand-horror-cyan-100 w-full p-2">
                        <div className="rounded-full h-12 bg-brand-horror-cyan-500 flex justify-end items-center pr-1" 
                                style = { { minWidth: "32px", width : (progression * 100) + "%" } }>
                            { <LoadingIcon className="h-10" /> 
                            }
                        </div>
                    </div>
                </div>
            </div>    
        </ModalWindow> 
    );
}

export default Loading;