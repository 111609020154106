import React from 'react';

interface DonateIconProps {
    className: string;
}

const DonateIcon:React.FC<DonateIconProps> = (props) => {
    return (    
		<svg className={props.className} viewBox="0 0 92 92" fill="#0AEFA9" stroke="#08CD91" strokeWidth="1.2"  xmlns="http://www.w3.org/2000/svg">

				<path d="M61.3314 49.0663C74.858 49.0663 85.8639 38.0604 85.8639 24.5338C85.8639 11.0071 74.858 0.0012207 61.3314 0.0012207C47.8047 0.0012207 36.7988 11.0071 36.7988 24.5338C36.7988 38.0604 47.8047 49.0663 61.3314 49.0663ZM61.3314 3.06768C73.1683 3.06768 82.7975 12.6967 82.7975 24.5338C82.7975 36.3709 73.1685 45.9999 61.3314 45.9999C49.4943 45.9999 39.8653 36.3709 39.8653 24.5338C39.8653 12.6967 49.4945 3.06768 61.3314 3.06768Z" />
				<path d="M91.1754 64.3994C89.4842 61.4677 85.7199 60.4665 82.894 62.1042L65.2458 70.6937C62.5028 72.0659 59.7337 72.0659 53.6649 72.0659C47.7327 72.0659 38.6755 70.5679 38.5851 70.5541C37.7418 70.4177 36.9583 70.9803 36.8203 71.8129C36.6806 72.6486 37.245 73.4398 38.0791 73.5778C38.4609 73.6421 47.4767 75.1326 53.6649 75.1326C60.0311 75.1326 63.2265 75.1326 66.6028 73.4444L84.3306 64.8105C85.0389 64.4012 85.8654 64.2923 86.6582 64.5038C87.4479 64.7169 88.1102 65.2245 88.5181 65.9328C88.929 66.6428 89.0379 67.4691 88.8262 68.2604C88.6146 69.0518 88.1071 69.7124 87.3543 70.148L66.6227 83.0506C66.5798 83.0797 62.1562 85.8657 52.1317 85.8657C43.441 85.8657 30.011 78.466 29.8177 78.3602C29.6797 78.2928 26.3539 76.666 19.9326 76.666C19.0863 76.666 18.3994 77.3529 18.3994 78.1992C18.3994 79.0455 19.0863 79.7324 19.9326 79.7324C25.5093 79.7324 28.3826 81.0741 28.3887 81.0741V81.0725C28.9667 81.3945 42.6436 88.9322 52.1317 88.9322C63.2021 88.9322 68.1116 85.743 68.2742 85.6341L88.9306 72.7775C90.3489 71.9587 91.3639 70.6355 91.7872 69.0546C92.2134 67.4706 91.9942 65.8177 91.1754 64.3994Z"/>
				<path d="M61.3313 32.2004C59.6402 32.2004 58.2647 30.8252 58.2647 29.1338C58.2647 28.2875 57.5794 27.6006 56.7315 27.6006C55.8835 27.6006 55.1982 28.2875 55.1982 29.1338C55.1982 31.9827 57.1593 34.3638 59.7981 35.0493V35.2671C59.7981 36.1134 60.4834 36.8003 61.3313 36.8003C62.1793 36.8003 62.8646 36.1134 62.8646 35.2671V35.0493C65.5034 34.364 67.4644 31.9827 67.4644 29.1338C67.4644 25.7513 64.7137 23.0007 61.3313 23.0007C59.6402 23.0007 58.2647 21.6254 58.2647 19.9341C58.2647 18.2429 59.64 16.8674 61.3313 16.8674C63.0225 16.8674 64.398 18.2427 64.398 19.9341C64.398 20.7804 65.0833 21.4673 65.9312 21.4673C66.7791 21.4673 67.4644 20.7804 67.4644 19.9341C67.4644 17.0852 65.5034 14.7041 62.8646 14.0186V13.8008C62.8646 12.9545 62.1793 12.2676 61.3313 12.2676C60.4834 12.2676 59.7981 12.9545 59.7981 13.8008V14.0186C57.1593 14.7039 55.1982 17.0852 55.1982 19.9341C55.1982 23.3166 57.949 26.0672 61.3313 26.0672C63.0225 26.0672 64.398 27.4425 64.398 29.1338C64.398 30.825 63.0227 32.2004 61.3313 32.2004Z" />
				<path d="M10.733 49.0664H1.53323C0.686925 49.0664 0 49.7533 0 50.5996C0 51.4459 0.686925 52.1329 1.53323 52.1329H10.733C11.5778 52.1329 12.2662 52.8212 12.2662 53.6661V87.3984C12.2662 88.2432 11.5778 88.9316 10.733 88.9316H1.53323C0.686925 88.9316 0 89.6185 0 90.4648C0 91.3111 0.686925 91.9981 1.53323 91.9981H10.733C13.269 91.9981 15.3328 89.9342 15.3328 87.3982V53.6663C15.3328 51.1302 13.269 49.0664 10.733 49.0664Z" />
				<path d="M19.9326 58.2663H32.199C33.9883 58.2663 37.0289 60.5479 39.7121 62.5595C43.0761 65.0833 46.2547 67.4661 49.0651 67.4661H58.2648C60.5938 67.4661 61.7499 68.5224 61.7806 68.5501C62.0796 68.8491 62.4722 68.9993 62.8647 68.9993C63.2573 68.9993 63.6497 68.8491 63.9487 68.5501C64.5481 67.9505 64.5481 66.9815 63.9487 66.3821C63.7464 66.1797 61.8635 64.3994 58.2648 64.3994H49.0651C47.2758 64.3994 44.2352 62.1178 41.552 60.1063C38.188 57.5825 35.0094 55.1997 32.199 55.1997H19.9326C19.0863 55.1997 18.3994 55.8866 18.3994 56.7329C18.3994 57.5792 19.0863 58.2663 19.9326 58.2663Z" />
				<path d="M7.66627 82.0324C7.66627 80.7643 6.63436 79.7324 5.36634 79.7324C4.09832 79.7324 3.06641 80.7643 3.06641 82.0324C3.06641 83.3004 4.09832 84.3323 5.36634 84.3323C6.63436 84.3323 7.66627 83.3004 7.66627 82.0324Z" />

		</svg>

    );
}

export default DonateIcon;