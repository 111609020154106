import React from 'react';
import { useTranslation } from 'react-i18next';
import CommingSoon from 'ts/Components/Overlays/CommingSoon';
import BigWindow from 'ts/Components/Windows/BigWindow';

interface RewardsWindowProps {
}

const RewardsWindow: React.FC<RewardsWindowProps> = (props) => {

    const { t } = useTranslation("translations");
    
    return (
        <BigWindow
            title={ t('rewards.title') }
        >
            <div
                className="grid grid-cols-6 gap-2 my-4 justify-evenly "
            >
                <span className="text-brand-green-100 text-xl h-16">20 Kč</span>
                <span className="text-brand-horror-turqoise-900 text-xl col-span-5">Váš vzkaz bude navždy uveden zde v historii donatů.</span>

                <span className="text-brand-green-200 text-xl h-16">200 Kč</span>
                <span className="text-brand-horror-turqoise-900 text-xl col-span-5">Poděkování ve videu v dalším díle vývojařského deníku.</span>

                <span className="text-brand-green-300 text-xl h-16">1 000 Kč</span>
                <span className="text-brand-horror-turqoise-900 text-xl col-span-5">NFT s postavou, která bude později sloužit jako vstupenka do hry.</span>

                <span className="text-brand-green-400 text-xl h-16">2 000 Kč</span>
                <span className="text-brand-horror-turqoise-900 text-xl col-span-5">VIP NFT s postavou, s předběžným přístup do testovací verze.</span>

                <span className="text-brand-green-500 text-xl h-16">5 000 Kč</span>
                <span className="text-brand-horror-turqoise-900 text-xl col-span-5">Můžete se stát NPC v této hře. Dostanete poukaz na vytvoření 3D modelu postavy do hry z vaší fotografie.</span>

                <span className="text-brand-green-600 text-xl h-16">6 000 Kč</span>
                <span className="text-brand-horror-turqoise-900 text-xl col-span-5">Dostanete navíc 3D model vaší postavy ze hry ve formátu .FBX</span>

                <span className="text-brand-green-700 text-xl h-16">10 000+ Kč</span>
                <span className="text-brand-horror-turqoise-900 text-xl col-span-5">Čím víc pošlete, tím důležitější roli v příběhu bude hrát vaše NPC.</span>
            </div>
            <span className="text-brand-horror-turqoise-900 text-brand-turqoise-900 text-2xl">
                Volné NPC :
            </span>
            <CommingSoon big={true}>
                <div className="flex flex-col">
                    <span>Test test test test test test test test test test test test test test test test test test test test</span>
                    <span>Test test test test test test test test test test test test test test test test test test test test</span>
                    <span>Test test test test test test test test test test test test test test test test test test test test</span>
                    <span>Test test test test test test test test test test test test test test test test test test test test</span>
                    <span>Test test test test test test test test test test test test test test test test test test test test</span>
                    <span>Test test test test test test test test test test test test test test test test test test test test</span>
                    <span>Test test test test test test test test test test test test test test test test test test test test</span>
                    <span>Test test test test test test test test test test test test test test test test test test test test</span>
                </div>
            </CommingSoon>
        </BigWindow>
    );
}


export default RewardsWindow;