import React from 'react';

import { useTranslation } from 'react-i18next';

import MainRouteMenu from './MainRouteMenu';

import IconHeaderButton from '../../Components/Buttons/IconHeaderButton';
import LanguageButton from '../Buttons/LanguageButton';
import DonateHistoryIcon from 'ts/Icons/Main/DonateHistoryIcon';
import GoalIcon from 'ts/Icons/Main/GoalIcon';
import AboutIcon from 'ts/Icons/Main/AboutIcon';
import VlogIcon from 'ts/Icons/Main/VlogIcon';
import RewardsIcon from 'ts/Icons/Main/RewardsIcon';

interface HeaderProps {
    goalWindowVisible: boolean;
    aboutWindowVisible: boolean;
    vlogWindowVisible: boolean;
    rewardsWindowVisible: boolean;
    donateHistoryWindowVisible: boolean;
    scrollToGoalWindow:Function;
    scrollToAboutWindow: Function;
    scrollToVlogWindow: Function;
    scrollToRewardsWindow: Function;
    scrollToDonateHistoryWindow: Function;
}

const Header:React.FC<HeaderProps> = (props) => {

    const { t } = useTranslation("translations");
    
    return ( 
        <div className = { "h-12 max-w-5xl w-full flex justify-center z-40 bg-opacity-50 bg-gradient-to-r from-brand-horror-turqoise-100 via-brand-gray-100 to-brand-horror-turqoise-100 fixed" }>
            <div className="border-brand-green-100 border-double border-l-2 border-r-4"></div>
                <div className = { "h-12 max-w-4xl w-full flex justify-between bg-brand-gray-100" }>
                    <div className="flex">
                        <MainRouteMenu />
                        <IconHeaderButton text = { t('goal.button') }
                                active = {props.goalWindowVisible}
                                handleClick = { props.scrollToGoalWindow }
                                icon = { <GoalIcon className="h-8 shrink-0 " /> } /> 
                        <IconHeaderButton text = { t('about.button') }
                                active = {!props.goalWindowVisible && props.aboutWindowVisible}
                                handleClick = { props.scrollToAboutWindow }
                                icon = { <AboutIcon className="h-8 shrink-0 " /> } /> 
                        <IconHeaderButton text = { t('vlog.button') }
                                active = {!props.aboutWindowVisible && props.vlogWindowVisible}
                                handleClick = { props.scrollToVlogWindow }
                                icon = { <VlogIcon className="h-8 shrink-0 " /> } /> 
                        <IconHeaderButton text = { t('rewards.button') }
                                active = {!props.vlogWindowVisible && props.rewardsWindowVisible}
                                handleClick = { props.scrollToRewardsWindow }
                                icon = { <RewardsIcon className="h-8 shrink-0 " /> } /> 
                        <IconHeaderButton text = { t('donateHistory.button') }
                                active = {!props.rewardsWindowVisible && props.donateHistoryWindowVisible}
                                handleClick = { props.scrollToDonateHistoryWindow }
                                icon = { <DonateHistoryIcon className="h-8 shrink-0 " /> } /> 

                    </div>
                    <LanguageButton />
                </div>
            <div className="border-brand-green-100 border-double border-l-4 border-r-2"></div>
        </div>
    );
    
}

export default Header;