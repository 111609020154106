import React from 'react';

import { useTranslation } from 'react-i18next';

import DeadWebsiteIcon from 'ts/Icons/Main/DeadWebsiteIcon';
import FacebookIcon from 'ts/Icons/Socials/FacebookIcon';
import InstagramIcon from 'ts/Icons/Socials/InstagramIcon';
import TwitterIcon from 'ts/Icons/Socials/TwitterIcon';
import RedditIcon from 'ts/Icons/Socials/RedditIcon';
import DiscordIcon from 'ts/Icons/Socials/DiscordIcon';
import Link from 'ts/Components/Buttons/Link';

const Footer: React.FC = (props) => {

    const { t } = useTranslation("translations");

    return (
            <div className = { "flex w-full justify-between opacity-80 hover:opacity-100 bg-brand-gray-100 z-50 px-16 py-4" }>
                <div className = { "flex flex-col justify-between"}>
                    <Link additionalClassName="flex items-center text-brand-blood-500 hover:text-brand-blood-600" href="http://www.dead-website.com">
                        <DeadWebsiteIcon className="h-12" />
                        <span className="text-4xl mx-4">DEAD-WEBSITE</span>
                    </Link>
                    <span className = { "text-white" }>
                        {t('footer.copyright')}
                    </span>
                </div>
                <div className = { "flex text-center"} >

                    <div className = "flex flex-col items-start">
                        <span className="text-2xl text-brand-horror-turqoise-900">Socials</span>
                        <div className="grid grid-cols-3 gap-1 ">
                            <Link additionalClassName="h-6 w-6 text-white hover:text-brand-green-900" href="">
                                <FacebookIcon className="h-6" />
                            </Link>
                            <Link additionalClassName="h-6 w-6 text-white hover:text-brand-green-900" href="">
                                <TwitterIcon className="h-6" />
                            </Link>
                            <Link additionalClassName="h-6 w-6 text-white hover:text-brand-green-900" href="">
                                <RedditIcon className="h-6" />
                            </Link>
                            <Link additionalClassName="h-6 w-6 text-white hover:text-brand-green-900" href="">
                                <DiscordIcon className="h-6" />
                            </Link>
                            <Link additionalClassName="h-6 w-6 text-white hover:text-brand-green-900" href="">
                                <InstagramIcon className="h-6" />
                            </Link>
                        </div>
                    </div>
                    <div className = "flex flex-col items-start ml-4">
                        <span className="text-2xl text-brand-horror-turqoise-900">Resources</span>
                        <Link additionalClassName="text-sm text-brand-horror-turqoise-700 hover:text-brand-horror-turqoise-900 " href="">Contact</Link>
                        <Link additionalClassName="text-sm text-brand-horror-turqoise-700 hover:text-brand-horror-turqoise-900 " href="">Whitepaper</Link>
                        <Link additionalClassName="text-sm text-brand-horror-turqoise-700 hover:text-brand-horror-turqoise-900 " href="">Terms & Conditions</Link>
                        <Link additionalClassName="text-sm text-brand-horror-turqoise-700 hover:text-brand-horror-turqoise-900 " href="">Privacy policy</Link>
                    </div>
                </div>
            </div >
    );
};

export default Footer;