import React from 'react';

interface DonateHistoryIconProps {
    className: string;
}

const DonateHistoryIcon:React.FC<DonateHistoryIconProps> = (props) => {
    return (    
		<svg className={props.className}  strokeWidth="2.0" viewBox="0 0 92 92" xmlns="http://www.w3.org/2000/svg">
			<path fill="#0AEFA9" stroke="#08CD91" d="M26.314 26.3823C31.3492 21.3298 38.3072 18.2083 45.9997 18.2083C61.3487 18.2083 73.7913 30.651 73.7913 45.9999C73.7913 61.349 61.3487 73.7916 45.9997 73.7916C30.6508 73.7916 18.208 61.349 18.208 45.9999C18.208 44.4121 16.9208 43.1249 15.333 43.1249C13.7452 43.1249 12.458 44.4121 12.458 45.9999C12.458 64.5245 27.4751 79.5416 45.9997 79.5416C64.5243 79.5416 79.5413 64.5245 79.5413 45.9999C79.5413 27.4754 64.5243 12.4583 45.9997 12.4583C36.7173 12.4583 28.3111 16.2326 22.2412 22.3234C22.1436 22.4213 22.0546 22.5245 21.9741 22.632L17.1534 17.8113C16.6275 17.2854 15.8449 17.1112 15.1456 17.3644C14.4463 17.6176 13.9566 18.2524 13.8893 18.9931L12.534 33.9012C12.4825 34.4677 12.6853 35.0278 13.0875 35.43C13.4897 35.8323 14.0498 36.035 14.6163 35.9835L29.5245 34.6283C30.2651 34.5609 30.9 34.0712 31.1531 33.372C31.4063 32.6726 31.2321 31.89 30.7062 31.3642L25.9985 26.6564C26.1085 26.5739 26.2141 26.4826 26.314 26.3823Z" />
			<path fill="#4AF7C2" stroke="#0AEFA9" d="M48.875 26.8333C48.875 25.2454 47.5878 23.9583 46 23.9583C44.4122 23.9583 43.125 25.2454 43.125 26.8333V45.9999C43.125 46.9912 43.6356 47.9124 44.4762 48.4379L55.9762 55.6254C57.3229 56.4668 59.0966 56.0578 59.938 54.7112C60.7794 53.3645 60.3704 51.5908 59.0237 50.7494L48.875 44.4064V26.8333Z" />
		</svg>
    );
}

export default DonateHistoryIcon;