import { createSlice } from '@reduxjs/toolkit';
import { Timestamp } from 'firebase/firestore';

import i18n from '../i18n';

export type ErrorVO = {
    message: string
}

export type DonateVO = {
    id: string,
    amount: Number,
    finalAmount: Number,
    date: Timestamp,
    message: string,
    name: string
};


const initialGuiState: {
    loading : boolean;
    confirmBox : boolean;
    confirmCallback: Function;
    confirmLabel : string;
    settings : {
        settingsID : string,
        language : string
    }
} = {
    loading: false,
    confirmBox: false,
    confirmCallback: () => {},
    confirmLabel: '',
    settings : {
        settingsID : "",
        language : i18n.language.slice(0, 2)
    }
};

const guiSlice = createSlice({
    name: 'gui',
    initialState: initialGuiState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setConfirmBox(state, action) {
            state.confirmBox = action.payload;
        },
        setConfirmCallback(state, action) {
            state.confirmCallback = action.payload;
        },
        confirmLabel(state, action) {
            state.confirmLabel = action.payload;
        },
        setSettings(state, action) {
            state.settings = action.payload;
        },
        showConfirmBox(state, action) {
            state.confirmCallback = action.payload.confirmCallback; 
            state.confirmLabel = action.payload.confirmLabel;
            state.confirmBox = true;
        },

        hideConfirmBox(state) {
            state.confirmBox = false; 
        }
    },
});


export const guiActions = guiSlice.actions;

export default guiSlice.reducer;