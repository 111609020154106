import React, { useEffect, useRef } from 'react';

import { useInView } from 'react-intersection-observer';

import AboutWindow from './Partials/Main/AboutWindow';
import DonateHistoryWindow from './Partials/Main/DonateHistoryWindow';
import GoalWindow from './Partials/Main/GoalWindow';
import RewardsWindow from './Partials/Main/RewardsWindow';
import VlogWindow from './Partials/Main/VlogWindow';

import Footer from './Partials/Footer/Footer';
import Header from './Partials/Header/Header';
import Modals from './Partials/Modal/Modals';

const App:React.FC = () => {

    
    const { 
        ref: goalWindowRef, 
        inView: goalWindowVisible,
        entry: goalEntry
    } = useInView();

    
    const { 
        ref: aboutWindowRef, 
        inView: aboutWindowVisible ,
        entry: aboutEntry
    } = useInView();

    
    const { 
        ref: vlogWindowRef, 
        inView: vlogWindowVisible ,
        entry: vlogEntry
    } = useInView();

    
    const { 
        ref: rewardsWindowRef, 
        inView: rewardsWindowVisible,
        entry: rewardsEntry
    } = useInView();

    
    const { 
        ref: donateHistoryWindowRef, 
        inView: donateHistoryWindowVisible,
        entry: donateHistoryEntry
    } = useInView();

        
    const { 
        ref: donateLoadingRef, 
        inView: donateLoading 
    } = useInView();
 
    return (
        <div className="flex flex w-full h-full justify-center cursor-brand-default">
            <div className="w-full max-w-[1280px] w-full mx-auto h-full bg-gradient-to-r from-black via-brand-horror-cyan-300 to-black cursor-brand-default">
                <div className="max-w-5xl h-full flex flex-col mx-auto bg-gradient-to-r from-brand-gray-100 via-brand-horror-turqoise-400 to-brand-gray-100 ">
                    <Header goalWindowVisible={goalWindowVisible} 
                            aboutWindowVisible={aboutWindowVisible} 
                            vlogWindowVisible={vlogWindowVisible} 
                            rewardsWindowVisible={rewardsWindowVisible} 
                            donateHistoryWindowVisible={donateHistoryWindowVisible}
                            scrollToGoalWindow={ () => {
                                if(goalEntry) {
                                    if(goalEntry.target) {
                                        goalEntry.target.scrollIntoView({behavior: 'smooth' });
                                    }
                                }
                            } }  
                            scrollToAboutWindow={ () => {
                                if(aboutEntry) {
                                    if(aboutEntry.target) {
                                        aboutEntry.target.scrollIntoView({behavior: 'smooth' });
                                    }
                                }
                            }}  
                            scrollToVlogWindow={ () => {
                                if(vlogEntry) {
                                    if(vlogEntry.target) {
                                        vlogEntry.target.scrollIntoView({behavior: 'smooth' });
                                    }
                                }
                            }}  
                            scrollToRewardsWindow={ () => {
                                if(rewardsEntry) {
                                    if(rewardsEntry.target) {
                                        rewardsEntry.target.scrollIntoView({behavior: 'smooth' });
                                    }
                                }
                            }}  
                            scrollToDonateHistoryWindow={ () => {
                                if(donateHistoryEntry) {
                                    if(donateHistoryEntry.target) {
                                        donateHistoryEntry.target.scrollIntoView({behavior: 'smooth' });
                                    }
                                }
                            }}  

                        
                    />
                    <div className="w-full h-full flex flex-col pt-16">
                        <div ref = {goalWindowRef}>            
                            <GoalWindow  />
                        </div> 
                        <div ref = {aboutWindowRef}> 
                            <AboutWindow  />
                        </div> 
                        <div ref = {vlogWindowRef} >
                            <VlogWindow />
                        </div>  
                        <div ref = {rewardsWindowRef}>
                            <RewardsWindow  />
                        </div>  
                        <div ref = {donateHistoryWindowRef}> 
                            <DonateHistoryWindow loading={donateLoading} />
                        </div> 
                        <div ref = {donateLoadingRef}></div>
                    </div>
                    <Modals />
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default App;
